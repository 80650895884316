export const STATE = {
    DEBUG: "DEBUG",
    INFO: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    UPLOADING: "UPLOADING",
    DONE: "DONE",
    INVALID: "INVALID",
    DISCARD: "DISCARD",
    VALID: "VALID",
    NONE: "NONE"
}

export const SUPPORT = {
    VVOIDS_FACEBOOK: "https://www.facebook.com/Zmadnesss",
    VVOIDS_INSTAGRAM: "https://www.instagram.com/zmadnesss/",
    VVOIDS_LINKEDIN: "https://www.linkedin.com/in/vdg-z/",
    VVOIDS_DISCORD: "https://discord.gg/EqGRf5M",
    VVOIDS_PSN: "https://psnprofiles.com/LiKeAbAwS_Vince?order=percent",
    VVOIDS_YOUTUBE: "https://www.youtube.com/channel/UCysUIX7btobPLX1GTttmEXA"
}

export const ENABLE_LOG = {
    AXIOS: true,
    NAVIGATE: true
}

export const DEFAULT = {
    GUID: "00000000-0000-0000-0000-000000000000",
    NUMBER: 0,
    TEXT: "",
    KB: 1024,
}

export const LANGUAGES = {
    NL: "nl",
    EN: "en",
    DE: "de",
    FR: "fr"
}

export const REGEX = {
    PASSWORD: "^(?=.*\\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$",
    ALPHANUMERIC: "^[a-zA-Z0-9]+$",
    DIGITS: "^\\+\\d+$",
    NUMBER_SPACED: "\\B(?=(\\d{3})+(?!\\d))"
}

export const ROUTES = {
    HOME: "/",
}