import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PageRedirect(props) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(props.redirect);
    });

    return (<></>);
}