import LogoExternal from '../../assets/sprites/logos/External.png';
import LogoIdleLight from '../../assets/sprites/logos/IdleLight.png';
import LogoLivingPanels from '../../assets/sprites/logos/LivingPanels.png';
import LogoSanctaLogo from '../../assets/sprites/logos/SanctaLogo.png';
import LogoCookiesInc from '../../assets/sprites/logos/CookiesInc.png';
import LogoWisePeople from '../../assets/sprites/logos/WisePeople.png';
import ContentImg1 from '../../assets/sprites/images/ContentImg1.jpg';
import ContentImg3 from '../../assets/sprites/images/ContentImg3.jpg';
import ContentImg4 from '../../assets/sprites/images/ContentImg4.jpg';
import { useTranslation } from "react-i18next";
import { Button, Card } from "antd";
import { GlobalOutlined, CloudOutlined, GoogleOutlined, LaptopOutlined, DatabaseOutlined, ApartmentOutlined, ControlOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive'

export default function HomeContent(props) {
    const { t } = useTranslation();

    const projects = [
        {
            name: "WiSE People",
            partner: {
                name: "WiSE People",
                url: "https://www.wisepeople.be"
            },
            shortDescription: t("wisepeople_description"),
            logo: LogoWisePeople,
            links: [
                {
                    name: t("wisepeople_link1"),
                    url: "https://www.wisepeople.be"
                }
            ]
        }
    ];
    const partnered = [
        {
            name: "Cookies Inc.",
            partner: {
                name: "PIXELCUBESTUDIOS",
                url: "https://www.pixelcubestudios.com/Home.html"
            },
            shortDescription: t("cookiesinc_description"),
            logo: LogoCookiesInc,
            links: [
                {
                    name: t("cookiesinc_link1"),
                    url: "https://vvoids.com/wiki/cookiesinc/"
                },
                {
                    name: t("cookiesinc_link2"),
                    url: "https://play.google.com/store/apps/details?id=com.pixelcubestudios.android.cookiecollector2"
                },
                {
                    name: t("cookiesinc_link3"),
                    url: "https://apps.apple.com/us/app/cookies-inc-idle-tycoon/id918606368"
                }
            ]
        },
        {
            name: "Idle LIGHT: The Last Gods",
            partner: {
                name: "Luminosity Productions",
                url: "https://idle-light.com/"
            },
            shortDescription: t("idlelight_description"),
            logo: LogoIdleLight,
            links: [
                {
                    name: t("idlelight_link1"),
                    url: "https://play.google.com/store/apps/details?id=com.LumiProd.IdleLIGHTthelastgods"
                },
                {
                    name: t("idlelight_link2"),
                    url: "https://discord.gg/YdG64zt%22"
                },
                {
                    name: t("idlelight_link3"),
                    url: "https://www.instagram.com/idlelight_/"
                }
            ]
        },
        {
            name: "Sancta Maria - Webapp",
            partner: {
                name: "Sancta Maria Basisschool",
                url: "http://www.sanctamariawillebroek.be/br/"
            },
            shortDescription: t("sancta_description"),
            logo: LogoSanctaLogo,
            links: [
                {
                    name: t("sancta_link1"),
                    url: "https://vvoids.com/webapp/sanctamaria/"
                }
            ]
        }
    ];
    const archive = [
        {
            name: "External",
            partner: null,
            shortDescription: t("external_description"),
            logo: LogoExternal,
            links: [
                {
                    name: t("external_link1"),
                    url: "https://vvoids.blob.core.windows.net/assets/public/downloads/projects/external/release/ExternalR.zip"
                },
                {
                    name: t("external_link2"),
                    url: "https://vvoids.blob.core.windows.net/assets/public/downloads/projects/external/beta/ExternalB.zip"
                }
            ]
        },
        {
            name: "Living Panels",
            partner: null,
            shortDescription: t("livingpanels_description"),
            logo: LogoLivingPanels,
            links: [
                {
                    name: t("livingpanels_link1"),
                    url: "https://vvoids.blob.core.windows.net/assets/public/downloads/projects/livingpanels/beta/LivingPanelsBeta.zip"
                }
            ]
        }
    ];

    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isStupidMobile = useMediaQuery({ maxWidth: 370 });

    return (
        <>
            <div className={isMobile ? "flex justify-around p-5 bg-vvoids-dark" : "flex justify-around p-10 bg-vvoids-dark"}>
                <div className="w-96">
                    <p className="font-semibold text-3xl m-3 text-vvoids-white">{t("content_1_title")}</p>
                    <p className="m-3 text-vvoids-white">{t("content_1_description")}</p>
                    <Button type="primary" className="m-3 text-vvoids-white bg-vvoids-purple" onClick={() => props.handleClickScroll("services")}>{t("content_1_cta")}</Button>
                </div>
                {isStupidMobile ? null : <img className={isMobile ? "h-32 w-32 rounded-full animate-invert shadow-lg shadow-vvoids-purple" : "h-60 w-60 rounded-full animate-invert shadow-lg shadow-vvoids-purple"} src={ContentImg1} alt="-" />}
            </div>
            <div className={isMobile ? "flex justify-around p-5 bg-vvoids-darker" : "flex justify-around p-10 bg-vvoids-darker"}>
                {isStupidMobile ? null : <img className={isMobile ? "h-32 w-32 rounded-full animate-invert shadow-lg shadow-vvoids-purple" : "h-60 w-60 rounded-full animate-invert shadow-lg shadow-vvoids-purple"} src={ContentImg4} alt="-" />}
                <div className="w-96">
                    <p className="font-semibold text-xl m-3 text-vvoids-white"></p>
                    <p className="m-3 text-vvoids-white">{t("content_2_description")}</p>
                    <Button type="primary" className="m-3 text-vvoids-white bg-vvoids-purple" onClick={() => props.handleClickScroll("projects")}>{t("content_2_cta")}</Button>
                </div>
            </div>
            <div className={isMobile ? "flex justify-around p-5 bg-vvoids-dark" : "flex justify-around p-10 bg-vvoids-dark"}>
                <div className="w-96">
                    <p className="font-semibold text-xl m-3 text-vvoids-white">{t("content_3_title")}</p>
                    <p className="m-3 text-vvoids-white">{t("content_3_description")}</p>
                    <Button type="primary" className="m-3 text-vvoids-white bg-vvoids-purple" onClick={() => props.handleClickScroll("contact")}>{t("content_3_cta")}</Button>
                </div>
                {isStupidMobile ? null : <img className={isMobile ? "h-32 w-32 rounded-full animate-invert shadow-lg shadow-vvoids-purple" : "h-60 w-60 rounded-full animate-invert shadow-lg shadow-vvoids-purple"} src={ContentImg3} alt="-" />}
            </div>
            <div id="services" className="text-center p-10 bg-vvoids-darker text-vvoids-white">
                <p className='font-bold text-3xl mb-10'>{t("services_title")}</p>
                <div className='flex flex-wrap justify-center gap-10'>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <GlobalOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_website_title")}</p>
                        <p>{t("service_website_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <GoogleOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_playstore_title")}</p>
                        <p>{t("service_playstore_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <CloudOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_azure_title")}</p>
                        <p>{t("service_azure_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <LaptopOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_device_title")}</p>
                        <p>{t("service_device_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <ControlOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_api_title")}</p>
                        <p>{t("service_api_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <DatabaseOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_database_title")}</p>
                        <p>{t("service_database_description")}</p>
                    </div>
                    <div className='max-w-sm bg-vvoids-dark rounded-3xl p-5'>
                        <ApartmentOutlined className='text-4xl mb-2' />
                        <p className='font-bold mb-1'>{t("service_remote_title")}</p>
                        <p>{t("service_remote_description")}</p>
                    </div>
                </div>
            </div>
            <div id="projects" className="text-center p-10 bg-vvoids-dark text-vvoids-white">
                <p className='font-bold text-3xl mb-10'>{t("projects_owned")}</p>
                <div className='flex flex-wrap justify-center gap-10'>
                    {
                        projects.map((project) => {
                            return <ReGameCard key={project.name} description={project.shortDescription} name={project.name} logo={project.logo} links={project.links} partner={project.partner} />
                        })
                    }
                    {
                        partnered.map((project) => {
                            return <ReGameCard key={project.name} description={project.shortDescription} name={project.name} logo={project.logo} links={project.links} partner={project.partner} />
                        })
                    }
                    {
                        archive.map((project) => {
                            return <ReGameCard key={project.name} description={project.shortDescription} name={project.name} logo={project.logo} links={project.links} partner={project.partner} />
                        })
                    }
                </div>
            </div >
        </>
    );
}

export function ReGameCard({ logo, name, description, links, partner }) {
    const isMobile = useMediaQuery({ maxWidth: 600 });

    return <Card className="max-w-sm depth h-min bg-vvoids-darkest" title={
        <div className="flex m-2">
            <div className="mt-auto mb-auto mr-2">
                <div className="h-12 w-12 text-center">
                    <img className="h-full object-contain block m-auto" alt={name} src={logo} />
                </div>
            </div>
            <div className="mr-12 text-left pl-2" style={{ borderLeft: "solid 1px #aaaaaa" }}>
                <p className="text-lg font-bold whitespace-normal break-words text-vvoids-white">{name}</p>
                <p className="font-normal whitespace-normal break-words text-vvoids-white">{description}</p>
            </div>
            {partner
                ? <a className="h-10 w-10 absolute top-0 right-0 flex" target='_blank' rel="noreferrer" href={partner.url}><GlobalOutlined className='block m-auto text-orange-400 invert_hover' /></a>
                : null
            }
        </div>} bordered={false}>
        <div className='text-left ml-1 mb-3'>
            {links ? links.map((link) => {
                return <div key={link.name} className="m-2 text-orange-400"><a target='_blank' rel="noreferrer" href={link.url}>{link.name}</a></div>
            }) : null}
        </div>
    </Card>
}