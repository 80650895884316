import { BrowserRouter, Routes, Route } from "react-router-dom";

import PageRedirect from "./pages/vvoids/redirect";
import Main from "./pages/vvoids/main";
import PagePrivacy from "./pages/vvoids/privacy";
import PageTerms from "./pages/vvoids/terms";

export default function Index() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"privacy"} element={<PagePrivacy
                />} />
                <Route path={"terms"} element={<PageTerms
                />} />
                <Route path={"/"} element={<Main
                />} />
                <Route path={"*"} element={<PageRedirect
                    redirect={"/"}
                />} />
            </Routes>
        </BrowserRouter>
    );
}